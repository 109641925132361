export const Heros = () => {
    return (
        <div>
            <div className='d-none d-lg-block'>
                <div className='row g-0 mt-0'>
                    <div className='col-sm-6 col-md-6'>
                        <div className='col-image-left'></div>
                    </div>
                    <div className='col-4 col-md-4 container d-flex justify-content-center align-items-center'>
                        <div className='ml-2'>
                            <h1>Vagonlar joylashuvi va holati haqida ma'lumotga ega bo'ling</h1>
                            <p className='lead'>
                                Vagonlarni temir yo'l, MTU, stansiya miqyosida joylashuvi
                                va holati haqida real vaqt mobaynida ma'lumot olish imkoniyati
                            </p>

                        </div>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-4 col-md-4 container d-flex 
                        justify-content-center align-items-center'>
                        <div className='ml-2'>
                            <h1>Tashishlarni tezkor boshqarish samaradorligi oshiruvchi imkoniyatlar</h1>
                            <p className='lead'>
                                Tashishlarni smena-sutkalik rejalashtirish, joriy rejalashtirishda
                                sifatli axborotlardan foydalanish,  o'rnatilgan rejalarni unumli
                                nazorat qilish, harakat tarkiblarini samarali taqsimlash
                            </p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6'>
                        <div className='col-image-right'></div>
                    </div>
                </div>
            </div>

            {/* Mobile Heros */}
            <div className='d-lg-none'>
                <div className='container'>
                    <div className='m-2'>
                        <div className='col-image-left'></div>
                        <div className='mt-2'>
                            <h1>Vagonlar joylashuvi va holati haqida ma'lumotga ega bo'ling</h1>
                            <p className='lead'>
                                Vagonlarni temir yo'l, MTU, stansiya miqyosida joylashuvi
                                va holati haqida real vaqt mobaynida ma'lumot olish imkoniyati
                            </p>

                        </div>
                    </div>
                    <div className='m-2'>
                        <div className='col-image-right'></div>
                        <div className='mt-2'>
                            <h1>Tashishlarni tezkor boshqarish samaradorligi oshiruvchi imkoniyatlar</h1>
                            <p className='lead'>
                                Tashishlarni smena-sutkalik rejalashtirish, joriy rejalashtirishda
                                sifatli axborotlardan foydalanish,  o'rnatilgan rejalarni unumli
                                nazorat qilish, harakat tarkiblarini samarali taqsimlash
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}