
//import { useOktaAuth } from "@okta/okta-react";
import { access } from "fs";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getToken, isUserLoggedIn } from "../../Auth/AuthService";

import AddWagonRequest from "../../models/AddWagonRequest";
import WagonSensor from "../../models/WagonSensor";
import WagonSimpleData from "../../models/WagonSimpleData";
import { Pagination } from "../utils/Pagination";
import { SpinnerLoading } from "../utils/SpinnerLoading";
import { SensorAdmin } from "./components/SensorAdmin";
import { WagonEdit } from "./components/wagon/WagonEdit";
import { WagonTableAdmin } from "./components/wagon/WagonTableAdmin";


export const ManageAssetsPage = () => {
    //const { authState } = useOktaAuth();
    const isAuth: Boolean = isUserLoggedIn();

    //New wagon
    const [number, setNumber] = useState(0);
    const [country_id, setCountry_id] = useState(1);
    const [wagonType_id] = useState(1);


    //Displays
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [addOrEdit, setAddOrEdit] = useState(true);
    const [wagonDelete, setWagonDelete] = useState(false);
    const [wagonEditt, setWagonEditt] = useState<WagonSimpleData>({ wagonNumber: 0, sensor: [], country: '' });
    const token: any = getToken();

    const deleteWagon = function deleting() {
        setSearchUrl('');
        setWagonDelete(!wagonDelete);
    }



    function countryField(value: string) {
        if (value === 'O`zbekiston') {
            setCountry_id(1);
        } else if ('Boshqa davlatlar') {
            setCountry_id(2);
        }
    }

    const handleNumberAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumber(Number(event.target.value));
        setDisplaySuccess(false);
    };

    async function submitNewWagon(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url = "https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/wagons";

        if (isAuth && number !== 0 && country_id >= 0) {
            const wagon: AddWagonRequest = new AddWagonRequest(number, country_id, wagonType_id);
            console.log(wagon);

            const submitNewWagonResponse = await fetch(url, {
                method: "POST",
                headers: {
                    //   Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                    Authorization: token,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify(wagon)
            });

            if (!submitNewWagonResponse.ok) {
                throw new Error('Something went wrong!');
            }

            setRefresh(true);
            console.log(submitNewWagonResponse);
            setAddOrEdit(true)
            setNumber(0);
            setCountry_id(1);
            setDisplayWarning(false);
            setDisplaySuccess(true);

        } else {
            setRefresh(false);
            setDisplayWarning(true);
            setDisplaySuccess(false);

        }

    }
    //Wagon show
    //--------------------------------------------------------------------------------------------------------------------------

    const [wagons, setWagons] = useState<WagonSimpleData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [wagonsPerPage] = useState(15);
    const [totalAmountOfWagons, setTotalAmountOfWagons] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [wagonNumber, setWagonNumber] = useState('');
    const [searchUrl, setSearchUrl] = useState('');
    const [numberError, setNumberError] = useState('');


    useEffect(() => {
        const fetchWagons = async () => {

            const baseUrl: string = "https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/wagons";
            let url: string = '';
            if (isAuth) {
                if (searchUrl === '') {
                    url = `${baseUrl}/all?page=${currentPage}&size=${wagonsPerPage}`;

                } else {
                    url = baseUrl + searchUrl
                }

                const requestOptions = {
                    method: 'GET',
                    headers: {
                        //  Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                        Authorization: token,
                        // 'Content-Type': 'application/json'
                    }
                };
                console.log(url);
                const response = await fetch(url, requestOptions);
                if (!response.ok) {
                    throw new Error('Something went wrong!')
                }
                const responseJson = await response.json();
                const responseData = responseJson.data;

                console.log(JSON.stringify(responseJson));

                console.log(responseData);
                setTotalAmountOfWagons(responseJson.totalElements);
                setTotalPages(responseJson.totalPages);

                const loadedWagons: WagonSimpleData[] = [];

                for (const key in responseData) {
                    const sensorDetail: WagonSensor[] = [];
                    for (const k in responseData[key].wagonSensors) {
                        sensorDetail.push({
                            id: responseData[key].wagonSensors[k].id,
                            number: responseData[key].wagonSensors[k].number,
                            type: responseData[key].wagonSensors[k].typeSensor.id

                        })
                        console.log(sensorDetail)
                    }
                    loadedWagons.push({
                        wagonNumber: responseData[key].number,
                        country: responseData[key].country.name,
                        sensor: sensorDetail

                    });

                }
                console.log(loadedWagons);
                setWagons(loadedWagons);
            }
            setAddOrEdit(true);
            setIsLoading(false);
            setWagonEditt({ wagonNumber: 0, sensor: [], country: '' });

        };

        fetchWagons().catch((error: any) => {
            setIsLoading(false);
            setHttpError(error.message);
        })

        window.scrollTo(0, 0);
        setRefresh(false);

    }, [//authState, 
        currentPage, searchUrl, refresh, wagonDelete]);



    if (isLoading) {
        return (
            <div className="container m-5">
                <SpinnerLoading />
            </div>
        )
    }

    if (httpError) {
        return (
            <div className="container m-5">
                <p>{httpError}</p>
            </div>
        )
    }


    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWagonNumber(event.target.value);
    };

    const handleSearchByWagonNumber = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Diqqat Html da patternlar ishlamaganda ishlashi uchun qo'shimcha yozilgan
        if (wagonNumber.length === 0) {
            setNumberError('Vagon raqamini kiriting');
            setSearchUrl('');
        } else if (wagonNumber.length < 8 || wagonNumber.length > 8) {
            setNumberError('Vagon raqami 8 ta raqamdan iborat bo`lsin');
            setSearchUrl('');
        } else {
            setSearchUrl(`/getWagon?number=${wagonNumber}`);
            setNumberError('');
        }
        setWagonNumber('');
    }


    const indexOfLastWagon: number = currentPage * wagonsPerPage;
    const indexOfFirstWagon: number = indexOfLastWagon - wagonsPerPage;
    let lastItem = wagonsPerPage * currentPage <= totalAmountOfWagons ?
        wagonsPerPage * currentPage : totalAmountOfWagons;

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);


    const wagonEditing = (edit: WagonSimpleData) => {
        setAddOrEdit(false);
        setWagonEditt(edit);
    }

    const addUpdate = function update() {
        setSearchUrl('');
        setRefresh(!refresh);
    }
    // console.log(authState?.accessToken?.claims.userType);
    //console.log(authState?.accessToken?.claims.sub);

    if (//authState?.accessToken?.claims.userType == undefined
        !isAuth) {
        return <Navigate to='/home' />
    }
    return (
        <div className='container'>
            <div className='mt-5'>
                <h3>Ma'lumotlarni tahrirlash</h3>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <button className='nav-link active' id='nav-add-book-tab' data-bs-toggle='tab'
                            data-bs-target='#nav-add-wagon' type='button' role='tab' aria-controls='nav-add-wagon'
                            aria-selected='false'
                        >
                            Vagonlarga tegishli ma'lumotlar
                        </button>
                        <button className='nav-link' id='nav-sensor-tab' data-bs-toggle='tab'
                            data-bs-target='#nav-sensor' type='button' role='tab' aria-controls='nav-sensor'
                            aria-selected='true'
                        >
                            Sensorlarga tegishli ma'lumotlar
                        </button>

                    </div>
                </nav>

                <div className='tab-content' id='nav-tabContent'>
                    <div className='tab-pane fade show active' id='nav-add-wagon' role='tabpanel'
                        aria-labelledby='nav-add-book-tab'>

                        {!addOrEdit &&
                            <WagonEdit wagonForEdit={wagonEditt} addUpdate={addUpdate} />}

                        <div className='container mt-5 mb-5'>
                            {displaySuccess &&
                                <div className='alert alert-success' role='alert'>
                                    Book added successfully
                                </div>
                            }
                            {displayWarning &&
                                <div className='alert alert-danger' role='alert'>
                                    All fields must be filled out
                                </div>
                            }
                            <div className='card'>

                                <div className='card-header'>
                                    Yangi vagonni qo'shish
                                </div>
                                <div className='card-body'>
                                    <form method='POST' onSubmit={submitNewWagon}>
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                <label className='form-label'>Vagon raqami</label>
                                                <input type="text" className='form-control' name='vagon raqami'
                                                    onChange={handleNumberAdd}


                                                    pattern='[0-9]{8}' required
                                                    value={number} />
                                            </div>

                                            <div className='col-md-3 mb-3'>
                                                <label className='form-label'> Davlatga tegishliligi</label>
                                                <button className='form-control btn btn-secondary dropdown-toggle' type='button'
                                                    id='dropdownMenuButton2' data-bs-toggle='dropdown' aria-expanded='false' >
                                                    {country_id === 1 ? 'O`zbekiston' : 'Boshqa davlatlar'}
                                                </button>
                                                <ul id='addNewBookId' className='dropdown-menu' aria-labelledby='dropdownMenuButton2'>
                                                    <li><a onClick={() => countryField('O`zbekiston')} className='dropdown-item'>O'zbekiston</a></li>
                                                    <li><a onClick={() => countryField('Boshqa davlatlar')} className='dropdown-item'>Boshqa davlatlar</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div>
                                            <button type='submit' className='btn btn-primary mt-3'  >
                                                Vagonni qo'shish
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                        <div className='container'>
                            <div className='card-body'>
                                <div>
                                    <p className='col-md-8 fs-4'>Vagon raqami orqali so'rov </p>
                                    <form onSubmit={handleSearchByWagonNumber}>
                                        <div className='d-flex md-10'>
                                            <div className='row mt-2'>
                                                <div className='col'>
                                                    <input className='form-control' type='text'
                                                        placeholder='Vagonning 8-lik raqami' aria-labelledby='Vagon turi'
                                                        onChange={handleNumberChange} value={wagonNumber} pattern="[0-9]{8}" required
                                                        title="8 ta raqamdan iborat faqat sonlar kiritilsin" />
                                                    {<div >{numberError}</div>}
                                                </div>
                                                <div className="col-auto">
                                                    <button className="btn btn-outline-success" type="submit"
                                                    >Izlash</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <p></p>
                            </div>

                            {totalAmountOfWagons > 0 ?
                                <>
                                    <div className='mt-3'>
                                        <h5>Natijalar soni: ({totalAmountOfWagons})</h5>
                                    </div>
                                    <p>
                                        {indexOfFirstWagon + 1} dan {lastItem} gacha. Umumiy: {totalAmountOfWagons}
                                    </p>
                                    <WagonTableAdmin wagon={wagons} indexOfFirstWagon={indexOfFirstWagon}
                                        wagonDelete={deleteWagon} edit={wagonEditing} />
                                </>
                                :
                                <div className='m-5'>
                                    <h3 className='danger'>
                                        Sizning so'rovingiz topilmadi. Qaytadan urinib ko'ring!
                                    </h3>
                                </div>
                            }
                            {totalPages > 1 &&
                                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
                            }
                        </div>

                    </div>
                    <div className='tab-pane fade' id='nav-sensor' role='tabpanel' aria-labelledby='nav-sensor-tab'>
                        <SensorAdmin />
                    </div>
                    <div className='tab-pane fade' id='nav-messages' role='tabpanel' aria-labelledby='nav-messages-tab'>
                    </div>
                </div>
            </div>
        </div>
    );



}