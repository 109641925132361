import { NumericLiteral } from "typescript";
import SensorData from "./SensorData";

class AddEditRequest {

    number: number;
    country_id: number;
    wagonType_id: number
    sensorNumbers: SensorData[];

    constructor(number: number, country_id: number, wagonType_id: number, sensorNumbers: SensorData[]) {
        this.number = number;
        this.country_id = country_id;
        this.wagonType_id = wagonType_id;
        this.sensorNumbers = sensorNumbers;
    }

}
export default AddEditRequest;

