import { useState } from "react";
import WagonModel from "../../../models/WagonModel";
import { RowItem } from "./RowItem";



export const SearchWagon: React.FC<{ wagon: WagonModel[], indexOfFirstWagon: number }> = (props) => {

    return (
        <div className="card mt-3 shadow p-3 mb-3 bg-body rounded">
            <div className='row g-0'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Vagon raqami</th>
                            <th scope='col'>Holati</th>
                            <th scope='col'>Stansiya</th>
                            <th scope='col'>MTU</th>
                            <th scope='col'>Sana</th>
                            <th scope='col'>Sensor turi</th>
                            <th scope='col'>Sensor raqami</th>
                            <th scope='col'>Temperatura</th>
                            <th scope='col'>Batareyka</th>

                        </tr>

                    </thead>

                    <tbody>
                        {props.wagon.map((wagon_insert, index) => (

                            <RowItem wagon={wagon_insert} key={wagon_insert.id} indexOfFirstWagon={index + props.indexOfFirstWagon + 1} />

                        ))

                        }

                    </tbody>
                </table>
            </div>
        </div>

    );

}