import React, { useState } from "react"
import DateFormat from "../../../models/DateFormat";
import WagonModel from "../../../models/WagonModel"


export const RowItem: React.FC<{ wagon: WagonModel, indexOfFirstWagon: number }> = (props) => {


    let date: Date = new Date();

    function convert(dateToConvert: string) {
        let date: Date = new Date(dateToConvert);
        const formatter = new Intl.DateTimeFormat('ru-RU', {
            day: '2-digit', month: '2-digit', year: 'numeric',
            hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false
        });
        const formattedDate = formatter.format(date);

        return formattedDate;

    }


    return (
        /*   <th scope='col'>Vagon raqami</th> 
           <th scope='col'>Stansiya</th>
           <th scope='col'>Holati</th>
           <th scope='col'>Sana</th>
           <th scope='col'>Sensor turi</th>
           <th scope='col'>Sensor raqami</th>
           <th scope='col'>Temperatura</th>
           <th scope='col'>Batareyka</th>*/
        <tr >
            <th scope='row'>{props.indexOfFirstWagon}</th>
            <td>{props.wagon.wagon_number}</td>
            <td>{props.wagon.isEmpty === 0 ? "Bo`sh" : "Yuklangan"}</td>
            <td>{props.wagon.stationName}</td>
            <td>{props.wagon.regionName}</td>
            <td>{convert(props.wagon.date)}</td>
            <td>{props.wagon.sensorType === "location" ? "GPS" : "Holat"}</td>
            <td>{props.wagon.sensor_number}</td>
            <td>{props.wagon.temperature}</td>
            <td>{props.wagon.battery}</td>
        </tr>

    )
}

