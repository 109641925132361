class AddSensorRequest {
    number: string;
    sensorTypeId: number;


    constructor(number: string, sensorTypeId: number) {
        this.number = number;
        this.sensorTypeId = sensorTypeId;

    }



}
export default AddSensorRequest;