import { Link } from "react-router-dom";

export const KutibOlish = () => {
    return (
        <div className='p-5 mb-0 bg-dark header'>
            <div className='container-fluid py-5 text-white 
                d-flex justify-content-center align-items-center'>
                <div>
                    <h1 className='display-5 fw-bold'>Harakat tarkibi nazorati va boshqaruvi uchun aniq ma'lumotlarga ega bo'ling</h1>



                    <Link type='button' className='btn btn-outline-light' to='/search'>
                        Qidiruv oynasiga o'tish
                    </Link>
                </div>
            </div>
        </div>
    );
}