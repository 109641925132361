import Sensor from "../../../../models/Sensor";
import { SensorRowItem } from "./SensorRowItem";

export const SensorTable: React.FC<{ sensor: Sensor[], indexOfFirstSensor: number, sensorDelete: any, sensorEdit: any }> = (props) => {


    return (
        <div className="card mt-3 shadow p-3 mb-3 bg-body rounded">
            <div className='row g-0'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Sensor raqami</th>
                            <th scope='col'>Vagon raqami</th>
                            <th scope='col'>Sensor turi</th>
                            <th scope='col'>Tahrirlash</th>
                            <th scope='col'>O'chirish</th>
                        </tr>

                    </thead>

                    <tbody>
                        {props.sensor.map((sensor_insert, index) => (

                            <SensorRowItem sensor={sensor_insert} key={sensor_insert.sensorNumber}
                                indexOfFirstSensor={index + props.indexOfFirstSensor + 1} sensorDelete={props.sensorDelete}
                                sensorEdit={props.sensorEdit} />

                        ))

                        }

                    </tbody>
                </table>
            </div>
        </div>

    );

}