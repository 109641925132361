import { useState } from "react";

export const SensorSearch: React.FC<{ handleSearchUrl: any }> = (props) => {

    const [sensorNumber, setSensorNumber] = useState('');
    const [numberError, setNumberError] = useState('');




    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSensorNumber(event.target.value);
    };

    const handleSearchByWagonNumber = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        // Diqqat Html da patternlar ishlamaganda ishlashi uchun qo'shimcha yozilgan
        if (sensorNumber.length === 0) {

            setNumberError('Sensor rqamini kiriting');
            props.handleSearchUrl('');
        } else {

            props.handleSearchUrl(`/getSensor?number=${sensorNumber}`);
            setNumberError('');

        }

        setSensorNumber('');

    }


    return (
        <div className='card-body'>
            <div>
                <p className='col-md-8 fs-4'>Vagon raqami orqali so'rov </p>
                <form onSubmit={handleSearchByWagonNumber}>
                    <div className='d-flex md-10'>
                        <div className='row mt-2'>
                            <div className='col'>
                                <input className='form-control' type='text'
                                    placeholder='Sensor raqami' aria-labelledby='sensor raqami'
                                    onChange={handleNumberChange} value={sensorNumber} required
                                    title="8 ta raqamdan iborat faqat sonlar kiritilsin" />
                                {<div >{numberError}</div>}
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-outline-success" type="submit"
                                >Izlash</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <p></p>
        </div>





    )





}