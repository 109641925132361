import { useState } from "react";
import WagonModel from "../../../../models/WagonModel";
import WagonSimpleData from "../../../../models/WagonSimpleData";
import { RowItemAdmin } from "./RowItemAdmin";



export const WagonTableAdmin: React.FC<{ wagon: WagonSimpleData[], indexOfFirstWagon: number, wagonDelete: any, edit: any }> = (props) => {


    return (
        <div className="card mt-3 shadow p-3 mb-3 bg-body rounded">
            <div className='row g-0'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Vagon raqami</th>
                            <th scope='col'>Davlat</th>
                            <th scope='col'>Holat sensori</th>
                            <th scope='col'>Joylashuvi sensori</th>
                            <th scope='col'>O'zgartirish</th>
                            <th scope='col'>O'chirish</th>
                        </tr>

                    </thead>

                    <tbody>
                        {props.wagon.map((wagon_insert, index) => (

                            <RowItemAdmin wagon={wagon_insert} key={wagon_insert.wagonNumber}
                                indexOfFirstWagon={index + props.indexOfFirstWagon + 1} wagonDelete={props.wagonDelete}
                                edit={props.edit} />

                        ))

                        }

                    </tbody>
                </table>
            </div>
        </div>

    );

}