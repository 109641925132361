import axios from "axios";
const AUTH_REST_API_BASE_URL = "/employee";
export const registerAPICall = (registerObj: {}) => axios.post('https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com' + '/employee/register', registerObj);
export const loginAPICall = (usernameOrEmail: String, password: String) => axios.post('https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com' + '/employee/login', { usernameOrEmail, password });
export const storeToken = (token: string) => localStorage.setItem("token", token);

export const getToken = () => localStorage.getItem("token");

export const saveLoggedInUser = (username: string, role: string) => {
    sessionStorage.setItem("authenticatedUser", username);
    sessionStorage.setItem("role", role);
}

export const isUserLoggedIn = () => {

    const username = sessionStorage.getItem("authenticatedUser");

    if (username == null) {
        return false;
    }
    else {
        return true;
    }
}

export const getLoggedInUser = () => {
    const username = sessionStorage.getItem("authenticatedUser");
    return username;
}

export const logout = () => {
    localStorage.clear();
    sessionStorage.clear();

}

export const isAdminUser = () => {
    let role = sessionStorage.getItem("role");
    if (role != null && role === 'ROLE_ADMIN') {
        return true;
    } else {
        return false;
    }
}