import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { registerAPICall } from './AuthService';



export const RegisterComponent = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const navigator = useNavigate();

    function handleRegistrationForm(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();

        const register = { firstName, lastName, userName, password, phoneNumber, email }

        console.log(register);

        registerAPICall(register).then((response) => {
            console.log(response.data);
            navigator("/login")
        }).catch(error => {
            console.error(error);
        })
    }

    return (
        <div className='container'>
            <br /> <br />
            <div className='row'>
                <div className='col-md-6 offset-md-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <h2 className='text-center'> User Registration Form </h2>
                        </div>

                        <div className='card-body'>
                            <form onSubmit={handleRegistrationForm}>
                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Firstname </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='firstname'
                                            className='form-control'
                                            placeholder='Enter firstname'
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Lastname </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='lastname'
                                            className='form-control'
                                            placeholder='Enter lastname'
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>


                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Username </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='username'
                                            className='form-control'
                                            placeholder='Enter username'
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Password </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='password'
                                            name='password'
                                            className='form-control'
                                            placeholder='Enter password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>

                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Phone number </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='phoneNumber'
                                            className='form-control'
                                            placeholder='Enter phone number'
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <label className='col-md-3 control-label'> Email </label>
                                    <div className='col-md-9'>
                                        <input
                                            type='text'
                                            name='email'
                                            className='form-control'
                                            placeholder='Enter email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        >
                                        </input>
                                    </div>
                                </div>

                                <div className='form-group mb-3'>
                                    <button className='btn btn-primary' type='submit' >Submit</button>

                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}

export default RegisterComponent