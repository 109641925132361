import { Heros } from "./components/Heros";
import { KutibOlish } from "./components/KutibOlish";


export const HomePage = () => {
    return (
        <>
            <KutibOlish />
            <Heros />
        </>
    );
}