//import { useOktaAuth } from "@okta/okta-react";
import React, { useState } from "react"
import { getToken } from "../../../../Auth/AuthService";
import DateFormat from "../../../../models/DateFormat";
import WagonModel from "../../../../models/WagonModel"
import WagonSimpleData from "../../../../models/WagonSimpleData";


export const RowItemAdmin: React.FC<{ wagon: WagonSimpleData, indexOfFirstWagon: number, wagonDelete: any, edit: any }> = (props) => {
    //   const { authState } = useOktaAuth();
    const token: any = getToken();
    async function deleteWagon() {
        const url = `https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/wagons?number=${props.wagon?.wagonNumber}`;
        const requestOptions = {
            method: 'DELETE',
            headers: {
                //   Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                Authorization: token,
                'Content-Type': 'application/json'
            }
        };
        const updateResponse = await fetch(url, requestOptions);
        if (!updateResponse.ok) {
            throw new Error('Something went wrong!');
        }
        props.wagonDelete();
    }
    let holat = null;
    let joylashuv = null;

    for (const key in props.wagon.sensor) {
        if (props.wagon.sensor[key].type === 1) {
            holat = props.wagon.sensor[key].number;
        } else if (props.wagon.sensor[key].type === 2) {
            joylashuv = props.wagon.sensor[key].number;
        }
    }

    console.log(holat + ' va ' + joylashuv);

    const edit = () => {
        props.edit(props.wagon)

    }

    return (

        <tr >
            <th scope='row'>{props.indexOfFirstWagon}</th>
            <td>{props.wagon.wagonNumber}</td>
            <td>{props.wagon?.country}</td>
            <td>{holat}</td>
            <td>{joylashuv} </td>
            <td><button className='btn btn-outline-success' type='button' onClick={edit}>O'zgartirish</button></td>
            <td><button className='btn btn-outline-success' type='button' onClick={deleteWagon}> O'chirish</button></td>

        </tr>

    )
}

