import { stat } from 'fs';
import { SetStateAction, useEffect, useState } from 'react';
import WagonModel from '../../models/WagonModel';
import { Pagination } from '../utils/Pagination';
import { SpinnerLoading } from '../utils/SpinnerLoading';
import { SearchWagon } from './components/SearchWagon';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { Maps } from './components/Maps';
import { getToken, isUserLoggedIn } from '../../Auth/AuthService';
//import { useOktaAuth } from '@okta/okta-react';



export const SearchWagonsPage = () => {
    const [wagons, setWagons] = useState<WagonModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [wagonsPerPage] = useState(15);
    const [totalAmountOfWagons, setTotalAmountOfWagons] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [wagonNumber, setWagonNumber] = useState('');
    const [wagonType, setWagonType] = useState('');
    const [stationName, setStationName] = useState('');
    const [wagonState, setWagonState] = useState('Vagon holatini tanlang');
    const [regionName, setRegionName] = useState('Mintaqaviy temir yo`l uzeli');
    const [searchUrl, setSearchUrl] = useState('');


    const [numberError, setNumberError] = useState('');
    const [wagonTypeError, setWagonTypeError] = useState('');

    const [refresh, setRefresh] = useState('');
    //  const { authState } = useOktaAuth();

    const isAuth = isUserLoggedIn();
    useEffect(() => {

        const fetchWagons = async () => {
            if (isAuth) {
                const baseUrl: string = "https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/wagonPositions";
                let url: string = '';
                if (searchUrl === '') {
                    url = `${baseUrl}/byCondition?page=${currentPage}&size=${wagonsPerPage}`;
                } else {
                    url = baseUrl + searchUrl
                }
                const token: any = getToken();
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        //     Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                        Authorization: token,
                        'Content-Type': 'application/json'
                    }
                };

                console.log(url);
                const response = await fetch(url, requestOptions);
                console.log(response);
                if (!response.ok) {
                    throw new Error('Something went wrong!')
                }
               
                const responseJson = await response.json();
                console.log(responseJson);
                const responseData = responseJson.data;

                console.log(responseData);
                setTotalAmountOfWagons(responseJson.totalElements);
                setTotalPages(responseJson.totalPages);

                const loadedWagons: WagonModel[] = [];

                for (const key in responseData) {
                    loadedWagons.push({
                        id: responseData[key].id,
                        date: responseData[key].date,
                        latitude: responseData[key].latitude,
                        longitude: responseData[key].longitude,
                        temperature: responseData[key].temperature,
                        battery: responseData[key].battery,
                        sensorType: responseData[key].sensor_Type,
                        stationName: responseData[key].station_Name,
                        regionName: responseData[key].region_Name,
                        isEmpty: responseData[key].is_Empty,
                        sensor_number: responseData[key].sensor_Number,
                        wagon_number: responseData[key].wagon_Number
                    });
                }
                setWagons(loadedWagons);
            }
            setIsLoading(false);

        };

        fetchWagons().catch((error: any) => {
            setIsLoading(false);
            setHttpError(error.message);
        })

        window.scrollTo(0, 0);

        const socket = new SockJS('https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/our-websocket');

        //const socket = new SockJS('http://w12-env.eba-5e2zsmu4.us-east-1.elasticbeanstalk.com/our-websocket');
        const stompClient = Stomp.over(socket);

        stompClient.connect({}, () => {
            stompClient.subscribe('/topic/messages', (m) => {
                const { message } = JSON.parse(m.body);
                console.log({ message });
                if (!(message === '')) {
                    setRefresh(message);
                }
            });
        }

        );
        setRefresh('');
        return () => {
            stompClient.disconnect(); // Clean up the WebSocket connection when component unmounts
        };

    }, [//authState, 
        currentPage, searchUrl, refresh]);

    if (isLoading) {
        return (
            <div className="container m-5">
                <SpinnerLoading />
            </div>
        )
    }

    if (httpError) {
        return (
            <div className="container m-5">
                <p>{httpError}</p>
            </div>
        )
    }


    let state: string = '';
    let region: string = '';


    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWagonType(event.target.value);

    };

    const searchHandelChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Diqqat Html da patternlar ishlamaganda ishlashi uchun qo'shimcha yozilgan
        if (wagonType.length > 3) {
            setWagonTypeError("3 sondan kam bo'lmagan raqam kiritilsin");
            setSearchUrl('');
        }
        setCurrentPage(1);

        if (wagonState === 'Umumiy' ||
            wagonState === 'Vagon holatini tanlang') {
            state = '';
        } else if (wagonState === 'Bo`sh') {
            state = '0';
        } else if (wagonState === 'Yuklangan') {
            state = '1';
        } else {
            state = wagonState;
        }

        if (regionName === 'Temir yo`l bo`yicha' ||
            regionName === 'Mintaqaviy temir yo`l uzeli') {
            region = '';
        } else {
            region = regionName;
        }

        if (wagonType.length > 2) {
            setWagonTypeError("2 sondan ko'p bo'lmasin");
            setSearchUrl('');
        } else {

            setSearchUrl(`/byCondition?page=${currentPage}&size=${wagonsPerPage}${!(state === '') ? '&state=' + state : ''}
            ${!(wagonType === '') ? '&type=' + wagonType : ''} ${!(stationName === '') ? '&station=' + stationName : ''}
            ${!(region === '') ? '&region=' + region : ''} `);
            setWagonTypeError('');
        }

        setWagonType('');
        setStationName('');
        setWagonState('Vagon holatini tanlang');
        setRegionName('Mintaqaviy temir yo`l uzeli');

    }




    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWagonNumber(event.target.value);
    };

    const handleSearchByWagonNumber = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        // Diqqat Html da patternlar ishlamaganda ishlashi uchun qo'shimcha yozilgan
        if (wagonNumber.length === 0) {

            setNumberError('Vagon raqamini kiriting');
            setSearchUrl('');
        } else if (wagonNumber.length < 8 || wagonNumber.length > 8) {

            setNumberError('Vagon raqami 8 ta raqamdan iborat bo`lsin');
            setSearchUrl('');
        } else {

            setSearchUrl(`/byWagon?page=${currentPage}&size=${wagonsPerPage}&number=${wagonNumber}`);
            setNumberError('');

        }

        setWagonNumber('');

    }



    const stateField = (value: string) => {
        if (
            value === 'Yuklangan' ||
            value === 'Bo`sh' ||
            value === 'Umumiy'
        ) {
            setWagonState(value);
        }
    }

    const regionField = (value: string) => {
        if (value === 'Tashkent' ||
            value === 'Buxoro' ||
            value === 'Qoqon' ||
            value === 'Qarshi' ||
            value === 'Termiz' ||
            value === 'Qongirot' ||
            value === 'Temir yo`l bo`yicha'
        ) {
            setRegionName(value);
        }
    }

    const indexOfLastWagon: number = currentPage * wagonsPerPage;
    const indexOfFirstWagon: number = indexOfLastWagon - wagonsPerPage;
    let lastItem = wagonsPerPage * currentPage <= totalAmountOfWagons ?
        wagonsPerPage * currentPage : totalAmountOfWagons;

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);


    return (

        <div>

            <div className='container'>

                <div className='card-body'>
                    <div>
                        <p className='col-md-8 fs-4'>Vagon raqami orqali so'rov </p>
                        <form onSubmit={handleSearchByWagonNumber}>
                            <div className='d-flex md-10'>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <input className='form-control' type='text'
                                            placeholder='Vagonning 8-lik raqami' aria-labelledby='Vagon turi'
                                            onChange={handleNumberChange} value={wagonNumber} pattern="[0-9]{8}" required
                                            title="8 ta raqamdan iborat faqat sonlar kiritilsin" />
                                        {<div >{numberError}</div>}
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-outline-success" type="submit"
                                        >Izlash</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <p></p>
                    <p className='col-md-8 fs-4'>Kengaytirilgan so'rov </p>

                    <form onSubmit={searchHandelChange}>
                        <div className='d-flex md-7'>

                            <div className='row mt-2'>

                                <div className="col">
                                    <input className='form-control me-2' type='text'
                                        placeholder='Vagon turi' aria-labelledby='Vagon turi'
                                        onChange={handleTypeChange} value={wagonType} pattern="[0-9]{1,2}" title="2 ta raqamdan iborat faqat sonlar kiritilsin" />
                                    {wagonTypeError}

                                    <small id="passwordHelpBlock" className="form-text text-muted">
                                        Vagonning birinchi bitta yoki birinchi ikkita raqamni kiriting: *2 yoki 28
                                    </small>

                                </div>

                                <div className='col'>

                                    <div className='dropdown'>
                                        <button className='btn btn-secondary dropdown-toggle' type='button'
                                            id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='true' >
                                            {wagonState}
                                        </button>
                                        <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                            <li onClick={() => stateField('Umumiy')}>
                                                <a className='dropdown-item' >
                                                    Umumiy
                                                </a>
                                            </li>
                                            <li onClick={() => stateField('Yuklangan')}>
                                                <a className='dropdown-item' >
                                                    Yuklangan
                                                </a>
                                            </li>
                                            <li onClick={() => stateField('Bo`sh')} >
                                                <a className='dropdown-item' >
                                                    Bo'sh
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                {((regionName === 'Mintaqaviy temir yo`l uzeli') || (regionName === 'Temir yo`l bo`yicha')) &&
                                    <div className='col'>
                                        <input className='form-control me-2' type='text'
                                            placeholder='Stansiya nomi' aria-labelledby='Stansiya nomi'
                                            onChange={e => setStationName(e.target.value)} value={stationName}
                                        />
                                    </div>
                                }

                                {(stationName === '') &&
                                    <div className='col'>
                                        <div className='dropdown'>
                                            <button className='btn btn-secondary dropdown-toggle' type='button'
                                                id='dropdownMenuButton2' data-bs-toggle='dropdown' aria-expanded='false' >
                                                {regionName}
                                            </button>
                                            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                                <li onClick={() => regionField('Temir yo`l bo`yicha')}>
                                                    <a className='dropdown-item' >
                                                        Temir yo`l bo`yicha
                                                    </a>
                                                </li>
                                                <li onClick={() => regionField('Tashkent')}>
                                                    <a className='dropdown-item' >
                                                        Tashkent
                                                    </a>
                                                </li>
                                                <li onClick={() => regionField('Buxoro')}>
                                                    <a className='dropdown-item' >
                                                        Buxoro
                                                    </a>
                                                </li>
                                                <li onClick={() => regionField('Qoqon')}>
                                                    <a className='dropdown-item' >
                                                        Qo'qon
                                                    </a>
                                                </li>
                                                <li onClick={() => regionField('Qarshi')}>
                                                    <a className='dropdown-item' >
                                                        Qarshi
                                                    </a>
                                                </li>
                                                <li onClick={() => regionField('Termiz')} >
                                                    <a className='dropdown-item' >
                                                        Termiz
                                                    </a>
                                                </li>
                                                <li onClick={() => regionField('Qongirot')} >
                                                    <a className='dropdown-item' >
                                                        Qo'ng'irot
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                }
                                <div className='col'>
                                    <button className='btn btn-outline-success'
                                        //  onClick={() => searchHandelChange() }
                                        type='submit'
                                    >
                                        Filtrlash
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <><Maps wagon={wagons} /></>


                {totalAmountOfWagons > 0 ?
                    <>
                        <div className='mt-3'>
                            <h5>Natijalar soni: ({totalAmountOfWagons})</h5>
                        </div>
                        <p>
                            {indexOfFirstWagon + 1} dan {lastItem} gacha. Umumiy: {totalAmountOfWagons}
                        </p>
                        <SearchWagon wagon={wagons} indexOfFirstWagon={indexOfFirstWagon} />
                    </>
                    :
                    <div className='m-5'>
                        <h3 className='danger'>
                            Sizning so'rovingiz topilmadi. Qaytadan urinib ko'ring!
                        </h3>

                    </div>
                }
                {totalPages > 1 &&
                    <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
                }
            </div>
        </div >
    );
}