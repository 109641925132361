import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { getToken, isUserLoggedIn } from "../../../../Auth/AuthService";
import AddEditRequest from "../../../../models/AddEditRequest";
import AddSensorRequest from "../../../../models/AddSensorRequest";
import SensorData from "../../../../models/SensorData";
import WagonSensor from "../../../../models/WagonSensor";
import WagonSimpleData from "../../../../models/WagonSimpleData";


export const WagonEdit: React.FC<{ wagonForEdit: WagonSimpleData, addUpdate: any }> = (props) => {

    // const { authState } = useOktaAuth();
    //New wagon

    const isAuth = isUserLoggedIn();
    const [letChange, setLetchange] = useState(false);
    const [httpError, setHttpError] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const [number, setNumber] = useState<number>(0);
    const [country_id, setCountry_id] = useState(1);
    const [loadSensor, setLoadSensor] = useState('');
    const [locationSensor, setLocationSensor] = useState('');


    const [loadSensors, setLoadSensors] = useState<string[]>(['']);
    const [locationSensors, setLocationSensors] = useState<string[]>(['']);
    const token: any = getToken();

    updateData(props.wagonForEdit)

    function updateData(data: WagonSimpleData) {
        if (number !== data.wagonNumber && letChange === false) {
            setNumber(data.wagonNumber);

            let holat: any;
            let joylashuv: any;

            for (const key in props.wagonForEdit.sensor) {
                if (props.wagonForEdit.sensor[key].type === 1) {
                    holat = props.wagonForEdit.sensor[key].number;

                } else if (props.wagonForEdit.sensor[key].type === 2) {
                    joylashuv = props.wagonForEdit.sensor[key].number;

                }
            }
            setLoadSensor(holat);
            setLocationSensor(joylashuv);

        }

    }

    console.log('props ' + props.wagonForEdit);
    console.log(number);

    //Displays
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);



    useEffect(() => {
        const fetchLoadsSensor = async () => {
            const baseUrl: string = "https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/sensor/type/1";
            let url: string = '';
            url = `${baseUrl}`;
            console.log(url);

            if (//authState && authState.isAuthenticated
                isAuth) {
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        //  Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                        Authorization: token,
                        'Content-Type': 'application/json'
                    }
                }
                );

                if (!response.ok) {
                    throw new Error('Something went wrong!')
                }
                const responseJson = await response.json();

                console.log('Data ' + responseJson);
                const loadSensors: string[] = [];
                for (let i = 0; i < responseJson.length; i++) {
                    loadSensors.push(responseJson[i]);
                }
                setLoadSensors(loadSensors);
            }
        };

        fetchLoadsSensor().catch((error: any) => {
            setHttpError(error.message);
        })

        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {

        const fetchLocationSensor = async () => {
            const baseUrl: string = "https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/sensor/type/2";
            let url: string = '';

            url = `${baseUrl}`;

            if (//authState && authState.isAuthenticated
                isAuth) {
                console.log(url);
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        //  Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                        Authorization: token,
                        'Content-Type': 'application/json'
                    }
                }
                );
                if (!response.ok) {
                    throw new Error('Something went wrong!')
                }
                const responseJson = await response.json();

                //console.log('Data ' + responseJson);
                const locationSensors: string[] = [];

                for (let i = 0; i < responseJson.length; i++) {
                    locationSensors.push(responseJson[i]);
                }
                setLocationSensors(locationSensors);
            }
        };


        fetchLocationSensor().catch((error: any) => {

            setHttpError(error.message);
        })
        window.scrollTo(0, 0);
    }, []);


    function countryField(value: string) {
        if (value === 'O`zbekiston') {
            setCountry_id(1);
        } else if ('Boshqa davlatlar') {
            setCountry_id(2);
        }
    }

    function editLoadSensor(value: string) {
        setLoadSensor(value);
    }

    function editLocationSensor(value: string) {
        setLocationSensor(value);
    }



    const handleNumberEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLetchange(true);
        setNumber(Number(event.target.value));
        setDisplaySuccess(false);
    };


    async function submitEdited(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url = `https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/wagons/${props.wagonForEdit.wagonNumber}`;
        const sensorData: SensorData[] = [];
        sensorData.push(new SensorData(1, loadSensor));
        sensorData.push(new SensorData(2, locationSensor));
        if (number != 0) {
            const sensor: AddEditRequest = new AddEditRequest(number, country_id, 1, sensorData);
            console.log('sensor malumotlari  ' + sensor.sensorNumbers[0].sensorNumber);

            const submitNewSensorResponse = await fetch(url, {
                method: "PUT",
                headers: {
                    // Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sensor)
            });

            if (!submitNewSensorResponse.ok) {
                throw new Error('Something went wrong!');
            }

            console.log(submitNewSensorResponse);

            setNumber(0);
            setLoadSensor('');
            setLocationSensor('');
            setCountry_id(1);
            setDisplayWarning(false);
            setDisplaySuccess(true);

            props.addUpdate();

        } else {
            setDisplayWarning(true);
            setDisplaySuccess(false);
        }
    }



    return (
        <div className='container mt-5 mb-5'>
            {displaySuccess &&
                <div className='alert alert-success' role='alert'>
                    Book added successfully
                </div>
            }
            {displayWarning &&
                <div className='alert alert-danger' role='alert'>
                    All fields must be filled out
                </div>
            }
            <div className='card'>

                <div className='card-header'>
                    Yangi vagonni qo'shish
                </div>
                <div className='card-body'>
                    <form method='POST'
                        onSubmit={submitEdited}
                    >
                        <div className='row'>
                            <div className='col-md-4 mb-3'>
                                <label className='form-label'>Vagon raqami</label>
                                <input type="text" className='form-control' name='vagon raqami'
                                    onChange={handleNumberEdit}
                                    pattern='[0-9]{8}' required
                                    value={number} />
                            </div>

                            <div className='col-md-2 mb-3'>
                                <label className='form-label'> Holat sensori</label>
                                <button className='form-control btn btn-secondary dropdown-toggle' type='button'
                                    id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false' >
                                    {loadSensor}


                                </button>
                                <ul id='EditWagonSensor1' className='dropdown-menu' aria-labelledby='dropdownMenuButton2'>
                                    {loadSensors.map((sensor, index) =>
                                        <li key={index}><a onClick={() => editLoadSensor(sensor)} className='dropdown-item'>{sensor}</a></li>)}
                                    <li ><a onClick={() => editLoadSensor('')} className='dropdown-item'> O'chirish </a></li>
                                </ul>
                            </div>

                            <div className='col-md-2 mb-3'>
                                <label className='form-label'> Joylashuv sensori</label>
                                <button className='form-control btn btn-secondary dropdown-toggle' type='button'
                                    id='dropdownMenuButton2' data-bs-toggle='dropdown' aria-expanded='false' >
                                    {locationSensor}
                                </button>
                                <ul id='EditWagonSensor2' className='dropdown-menu' aria-labelledby='dropdownMenuButton2'>
                                    {locationSensors.map((sensor, index) =>
                                        <li key={index}><a onClick={() => editLocationSensor(sensor)} className='dropdown-item'>{sensor}</a></li>
                                    )}
                                    <li ><a onClick={() => editLocationSensor('')} className='dropdown-item'> O'chirish </a></li>
                                </ul>
                            </div>

                            <div className='col-md-2 mb-3'>
                                <label className='form-label'> Davlatga tegishliligi</label>
                                <button className='form-control btn btn-secondary dropdown-toggle' type='button'
                                    id='dropdownMenuButton2' data-bs-toggle='dropdown' aria-expanded='false' >
                                    {country_id === 1 ? 'O`zbekiston' : 'Boshqa davlatlar'}
                                </button>
                                <ul id='addNewBookId' className='dropdown-menu' aria-labelledby='dropdownMenuButton2'>
                                    <li><a onClick={() => countryField('O`zbekiston')} className='dropdown-item'>O'zbekiston</a></li>
                                    <li><a onClick={() => countryField('Boshqa davlatlar')} className='dropdown-item'>Boshqa davlatlar</a></li>
                                </ul>
                            </div>



                        </div>

                        <div>
                            <button type='submit' className='btn btn-primary mt-3'  >
                                Vagon ma'lumotlarini o'zgartirish
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );


}