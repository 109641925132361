import { NumericLiteral } from "typescript";

class AddWagonRequest {

    number: number;

    country_id: number;
    wagonType_id: number

    constructor(number: number, country_id: number, wagonType_id: number) {
        this.number = number;
        this.country_id = country_id;
        this.wagonType_id = wagonType_id;
    }

}
export default AddWagonRequest;

