class SensorData {
    idType: number;
    sensorNumber: string;


    constructor(idType: number, sensorNumber: string
    ) {
        this.idType = idType;
        this.sensorNumber = sensorNumber;

    }


}
export default SensorData;