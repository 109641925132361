import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";
import { getToken, isUserLoggedIn } from "../../../../Auth/AuthService";
import AddSensorRequest from "../../../../models/AddSensorRequest";
import SensorType from "../../../../models/SensorType";
import WagonId from "../../../../models/WagonId";

export const SensorAdd: React.FC<{ addUpdate: any }> = (props) => {
    //const { authState } = useOktaAuth();
    //New wagon
    const [sensorNumber, setSensorNumber] = useState('');
    const [sensorTypeId, setSensorTypeId] = useState(0);
    const [sensorTypeField, setSensorTypeField] = useState('Sensor turi');
    const token: any = getToken();


    const [wagons, setWagons] = useState<WagonId[]>([]);
    const [sensorType, setSensorType] = useState<SensorType[]>([]);


    //Displays
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const isAuth = isUserLoggedIn();



    const handleNumberAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSensorNumber(event.target.value);
        setDisplaySuccess(false);
    };


    async function submitNewSensor(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const url = "https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/sensor";
        if (//authState?.isAuthenticated 
            isAuth && sensorNumber != '' && sensorTypeId > 0) {
            const sensor: AddSensorRequest = new AddSensorRequest(sensorNumber, sensorTypeId);
            console.log(sensor);

            const submitNewSensorResponse = await fetch(url, {
                method: "POST",
                headers: {
                    // Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                    Authorization: token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(sensor)
            });

            if (!submitNewSensorResponse.ok) {
                throw new Error('Something went wrong!');
            }
            props.addUpdate();
            console.log(submitNewSensorResponse);

            setSensorNumber('');
            setSensorTypeId(0);
            setDisplayWarning(false);
            setDisplaySuccess(true);
            setSensorTypeField('Sensor turi');

        } else {
            setDisplayWarning(true);
            setDisplaySuccess(false);
        }
    }


    function typeIdField(value: string) {
        if (value === 'Joylashuv') {
            setSensorTypeId(1);
            setSensorTypeField('Joylashuv');
        } else if ('Holat') {
            setSensorTypeId(2);
            setSensorTypeField('Holat');
        }
    }



    return (
        <div className='container mt-5 mb-5'>
            {displaySuccess &&
                <div className='alert alert-success' role='alert'>
                    Sensor muvaffaqiyatli qo'shildi
                </div>
            }
            {displayWarning &&
                <div className='alert alert-danger' role='alert'>
                    Barcha ma'lumotlar to'ldirilishi shart
                </div>
            }
            <div className='card'>

                <div className='card-header'>
                    Yangi sensorni qo'shish
                </div>
                <div className='card-body'>
                    <form method='POST' onSubmit={submitNewSensor}>
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                                <label className='form-label'>Sensor raqami</label>
                                <input type="text" className='form-control' name='vagon raqami'
                                    onChange={handleNumberAdd}
                                    required
                                    value={sensorNumber} />
                            </div>

                            <div className='col-md-3 mb-3'>
                                <label className='form-label'> Sensor turi</label>
                                <button className='form-control btn btn-secondary dropdown-toggle' type='button'
                                    id='dropdownMenuButton2' data-bs-toggle='dropdown' aria-expanded='false'  >
                                    {sensorTypeField}
                                </button>
                                <ul id='addNewBookId' className='dropdown-menu' aria-labelledby='dropdownMenuButton2' >
                                    <li><a onClick={() => typeIdField('Joylashuv')} className='dropdown-item'>Joylashuv</a></li>
                                    <li><a onClick={() => typeIdField('Holat')} className='dropdown-item'>Holat</a></li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <button type='submit' className='btn btn-primary mt-3'  >
                                Sensorni qo'shish
                            </button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    );


}