//import { useOktaAuth } from "@okta/okta-react";
import { getToken } from "../../../../Auth/AuthService";
import Sensor from "../../../../models/Sensor";
import { SensorEdit } from "./SensorEdit";

export const SensorRowItem: React.FC<{ sensor: Sensor, indexOfFirstSensor: number, sensorDelete: any, sensorEdit: any }> = (props) => {
    //const { authState } = useOktaAuth();
    const token: any = getToken();
    async function deleteSensor() {

        const url = `https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/sensor?number=${props.sensor?.sensorNumber}`;
        const requestOptions = {
            method: 'DELETE',
            headers: {
                Authorization: token,
                // Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                'Content-Type': 'application/json'
            }
        };
        const updateResponse = await fetch(url, requestOptions);
        if (!updateResponse.ok) {
            throw new Error('Something went wrong!');
        }
        props.sensorDelete();
        console.log('URl ' + url);
    }


    const edit = () => {
        props.sensorEdit(props.sensor.sensorNumber)

    }



    return (

        <tr >
            <th scope='row'>{props.indexOfFirstSensor}</th>
            <td>{props.sensor.sensorNumber}</td>
            <td>{props.sensor.wagonNumber}</td>
            <td>{props.sensor.sensorName}</td>
            <td><button className='btn btn-outline-success' type='button' onClick={edit} > Tahrirlash</button></td>
            <td><button className='btn btn-outline-success' type='button' onClick={deleteSensor}> O'chirish</button></td>

        </tr>

    )
}
