//import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { getToken, isUserLoggedIn } from "../../../Auth/AuthService";
import AddSensorRequest from "../../../models/AddSensorRequest";
import Sensor from "../../../models/Sensor";
import { Pagination } from "../../utils/Pagination";
import { SpinnerLoading } from "../../utils/SpinnerLoading";
import { SensorAdd } from "./sensor/SensorAdd";

import { SensorEdit } from "./sensor/SensorEdit";
import { SensorSearch } from "./sensor/SensorSearch";
import { SensorTable } from "./sensor/SensorTable";

export const SensorAdmin = () => {
    //const { authState } = useOktaAuth();
    const [sensors, setSensors] = useState<Sensor[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sensorsPerPage] = useState(15);
    const [totalAmountOfWagons, setTotalAmountOfWagons] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [searchUrl, setSearchUrl] = useState('');
    const [sensorDelete, setSensorDelete] = useState(false);
    const [refresh, setRefresh] = useState(false);


    const [editedSensor, setEditedSensor] = useState('');

    const [addOrEdit, setAddOrEdit] = useState(true);
    const isAuth = isUserLoggedIn();


    useEffect(() => {
        const fetchSensors = async () => {
            const baseUrl: string = "https://asmpsk-springboot-postgresql-2fcd4cbae7a6.herokuapp.com/sensor";
            let url: string = '';

            if (//authState && authState.isAuthenticated
                isAuth) {
                if (searchUrl === '') {
                    url = `${baseUrl}?page=${currentPage}&size=${sensorsPerPage}`;
                } else {
                    url = baseUrl + searchUrl
                }
                console.log(url);
                const token: any = getToken();
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        //   Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                        Authorization: token
                        // 'Content-Type': 'application/json'
                    }
                };



                const response = await fetch(url, requestOptions);
                if (!response.ok) {
                    throw new Error('Something went wrong!')
                }
                const responseJson = await response.json();
                const responseData = responseJson.data;

                console.log('Data ' + responseData);
                setTotalAmountOfWagons(responseJson.totalElements);
                setTotalPages(responseJson.totalPages);

                const loadedWagons: Sensor[] = [];

                for (const key in responseData) {
                    loadedWagons.push({
                        sensorNumber: responseData[key].sensorNumber,
                        wagonNumber: responseData[key].wagonNumber,
                        sensorName: responseData[key].sensorName,
                    });
                }
                setSensors(loadedWagons);
            }
            setIsLoading(false);
        };
        setAddOrEdit(true);

        fetchSensors().catch((error: any) => {
            setIsLoading(false);
            setHttpError(error.message);
        })
        window.scrollTo(0, 0);
    }, [//authState, 
        currentPage, searchUrl, sensorDelete, refresh]);


    const sensorsDelete = function deleteS() {
        setSearchUrl('');
        setSensorDelete(!sensorDelete);

    }



    const addUpdate = function update() {
        setSearchUrl('');
        setRefresh(!refresh);
    }

    if (isLoading) {
        return (
            <div className="container m-5">
                <SpinnerLoading />
            </div>
        )
    }

    if (httpError) {
        return (
            <div className="container m-5">
                <p>{httpError}</p>
            </div>
        )
    }

    const indexOfLastSensor: number = currentPage * sensorsPerPage;
    const indexOfFirstSensor: number = indexOfLastSensor - sensorsPerPage;
    let lastItem = sensorsPerPage * currentPage <= totalAmountOfWagons ?
        sensorsPerPage * currentPage : totalAmountOfWagons;

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleSearchUrl = (urlS: string) => setSearchUrl(urlS);


    const sensorEdit = (sensorEdit: string) => {
        setAddOrEdit(false);
        setEditedSensor(sensorEdit);

    }

    console.log('Edited sensor = ' + editedSensor);


    return (
        <div className='container'>

            {addOrEdit && <SensorAdd addUpdate={addUpdate} />}

            {!addOrEdit && <SensorEdit sensorForEdit={editedSensor} addUpdate={addUpdate} />}

            <SensorSearch handleSearchUrl={handleSearchUrl} />

            {totalAmountOfWagons > 0 ?
                <>
                    <div className='mt-3'>
                        <h5>Natijalar soni: ({totalAmountOfWagons})</h5>
                    </div>
                    <p>
                        {indexOfFirstSensor + 1} dan {lastItem} gacha. Umumiy: {totalAmountOfWagons}
                    </p>
                    <SensorTable sensor={sensors} indexOfFirstSensor={indexOfFirstSensor} sensorDelete={sensorsDelete}
                        sensorEdit={sensorEdit} />

                </>
                :
                <div className='m-5'>
                    <h3 className='danger'>
                        Sizning so'rovingiz topilmadi. Qaytadan urinib ko'ring!
                    </h3>

                </div>
            }
            {totalPages > 1 &&
                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
            }
        </div>
    )

}





