import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import WagonModel from '../../../models/WagonModel';


export const Maps: React.FC<{ wagon: WagonModel[] }> = (props) => {



    return (
        <MapContainer center={[40.360672, 65.468295]} zoom={6} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {props.wagon.map(data => (
                <Marker key={data.id} position={[data.latitude, data.longitude]}>
                    <Popup position={[data.latitude, data.longitude]}>
                        <div>
                            <p>Vagon №:   {data?.wagon_number}</p>
                            <p>Holati:  {data?.isEmpty === 0 ? 'Bo`sh' : 'Yuklangan'}</p>
                            <p>Quvvati: {data?.battery}</p>
                            <p>Temperatura:  {data?.temperature}</p>

                        </div>
                    </Popup>
                </Marker>

            ))}
        </MapContainer>

    );
}