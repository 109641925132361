import { Link, NavLink } from "react-router-dom";
//import { useOktaAuth } from '@okta/okta-react';
import { SpinnerLoading } from "../utils/SpinnerLoading";
import { isAdminUser, isUserLoggedIn, logout } from "../../Auth/AuthService";
import { isatty } from "tty";



export const Navbar = () => {

    const handleLogout = () => {
        logout();
    }

    const isAuth = isUserLoggedIn();
    const isAdmin = isAdminUser();
    //const { oktaAuth, authState } = useOktaAuth();

    // if (!isAuth) {
    //     return <SpinnerLoading />
    // }
    // const handleLogout = async () => oktaAuth.signOut();

    //console.log(authState);

    return (
        <nav className='navbar navbar-expand-lg navbar-dark main-color py-3'>
            <div className='container-fluid'>
                <span className='navbar-brand'>HTKANT</span>
                <button className='navbar-toggler' type='button'
                    data-bs-toggle='collapse' data-bs-target='#navbarNavDropdown'
                    aria-controls='navbarNavDropdown' aria-expanded='false'
                    aria-label='Toggle Navigation'
                >
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className='collapse navbar-collapse' id='navbarNavDropdown'>
                    <ul className='navbar-nav'>
                        <li className='nav-item'>
                            <NavLink className='nav-link' to='/home'> Bosh sahifa</NavLink>
                        </li>
                        {//authState.isAuthenticated &&
                            isAuth &&
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/search'> Vagon haqida ma'lumot</NavLink>
                            </li>
                        }
                        {//authState.isAuthenticated && authState.accessToken?.claims?.userType === 'admin' &&
                            isAuth && isAdmin &&
                            <li className='nav-item'>
                                <NavLink className='nav-link' to='/admin'> Ma'lumotlar qo'shish</NavLink>
                            </li>
                        }

                    </ul>
                    <ul className='navbar-nav ms-auto'>


                        {!isAuth &&

                            <li className='nav-item m-1'>
                                <Link type='button' className='btn btn-outline-light' to='/register'>
                                    Register
                                </Link>
                            </li>
                        }
                        {!isAuth &&
                            <li className='nav-item m-1'>
                                <Link type='button' className='btn btn-outline-light' to='/login'>
                                    Login
                                </Link>
                            </li>}
                        {isAuth &&
                            <li className='nav-item m-1'>
                                <Link type='button' className='btn btn-outline-light' to='/' onClick={handleLogout}>
                                    Logout
                                </Link>
                            </li>}


                    </ul>
                </div>
            </div>
        </nav>
    );
}

