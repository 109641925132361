import React, { ReactNode } from 'react';

import './App.css';
import { Footer } from './layouts/NavbarAndFooter/Footer';

import { Navbar } from './layouts/NavbarAndFooter/Navbar';
import { HomePage } from './layouts/HomePage/HomePage';
import { SearchWagonsPage } from './layouts/SearchWagonPage/SearchWagonsPage';
import {  Navigate, Route, RouteProps, Routes, useNavigate } from 'react-router-dom';
import { ManageAssetsPage } from './layouts/ManageAssetsPage/ManageAssetsPage';

import { oktaConfig } from './lib/oktaConfig';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import LoginWidget from './Auth/LoginWidget';
import RegisterComponent from './Auth/RegisterComponent';
import LoginComponent from './Auth/LoginComponent';
import { isAdminUser, isUserLoggedIn } from './Auth/AuthService';
import { TextSpan } from 'typescript';



//const oktaAuth = new OktaAuth(oktaConfig);


export const App = () => {



  // const PrivateRoute: React.FC<{ path: string; element: React.ReactNode }> = ({ path, element }) => {
  //   const isAuth = isUserLoggedIn();

  //   return isAuth ? (
  //     <Route path={path} element={element} />
  //   ) : (
  //     <Navigate to="/" />
  //   );
  // };


  const PrivateRoute: React.FC<{ element: any }> = ({ element }) => {
    const isAuth = isUserLoggedIn();

    return isAuth ? (
      element
    ) : (
      <Navigate to="/" />
    );
  };

  const PrivateRouteAdmin: React.FC<{ element: any }> = ({ element }) => {
    const isAuthAdmin = isAdminUser();

    return isAuthAdmin ? (
      element
    ) : (
      <Navigate to="/" />
    );
  };




  const customAuthHandler = () => {
    navigate('/login');
  }

  const navigate = useNavigate();

  // const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
  //   navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  // };


  return (

    <div className="d-flex flex-column min-vh-100">
      {/* <Security 
      //oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}
      > */}
      <Navbar />
      <div className='flex-grow-1'>
        <Routes>

          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />


          <Route path='/search' element={
            <PrivateRoute element={<SearchWagonsPage />} />
          } />

          <Route
            path="/admin"
            element={<PrivateRouteAdmin element={<ManageAssetsPage />} />}
          />



          <Route path='/register' element={
            <RegisterComponent />} />


          <Route path='/login' element={<LoginComponent />} />


      
          <Route path='/login1/callback' Component={LoginCallback} />


        </Routes>
      </div>
      <Footer />
      {/* </Security> */}
    </div>
  );
}


